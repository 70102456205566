import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "instructor" }
const _hoisted_2 = { class: "instructor__container" }
const _hoisted_3 = { class: "instructor__wrap" }
const _hoisted_4 = { class: "instructor__mobile-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "instructor__value" }
const _hoisted_7 = { class: "instructor__value" }
const _hoisted_8 = { class: "instructor__value" }
const _hoisted_9 = { class: "instructor__value" }
const _hoisted_10 = {
  key: 0,
  class: "instructor__error"
}
const _hoisted_11 = {
  key: 1,
  class: "instructor__code-copy-title"
}
const _hoisted_12 = {
  key: 2,
  class: "instructor__code-copy-wrap"
}
const _hoisted_13 = { class: "instructor__code-copy" }
const _hoisted_14 = { class: "instructor__copy-button" }
const _hoisted_15 = { class: "instructor__controls" }
const _hoisted_16 = {
  key: 0,
  class: "instructor__table-stydents"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "table__body-id" }
const _hoisted_19 = { class: "table__body-little-tr" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "table__body-big-tr" }
const _hoisted_22 = { class: "table__body-big-tr" }
const _hoisted_23 = { class: "instructor__popup-buttons" }
const _hoisted_24 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_CopyInstructor = _resolveComponent("CopyInstructor")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin/instructors",
      class: "instructor__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (this.instructorData?.image === null)
              ? (_openBlock(), _createBlock(_component_PhotoMock, {
                  key: 0,
                  class: "instructor__photo"
                }))
              : _createCommentVNode("", true),
            (this.instructorData?.image !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: this.instructorData?.image,
                  class: "instructor__photo",
                  alt: ""
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "instructor__title" }, " Full Name ", -1)),
          _createElementVNode("div", _hoisted_6, _toDisplayString(this.instructorData?.full_name), 1),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "instructor__title" }, " Email ", -1)),
          _createElementVNode("div", _hoisted_7, _toDisplayString(this.instructorData?.email), 1),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "instructor__title" }, " Phone Number ", -1)),
          _createElementVNode("div", _hoisted_8, _toDisplayString(this.instructorData?.phone?.value), 1),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "instructor__title" }, " Location ", -1)),
          _createElementVNode("div", _hoisted_9, _toDisplayString(this.instructorData?.location), 1),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "instructor__title" }, " Sport ", -1)),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "instructor__value" }, " Swimming ", -1)),
          (this.generateCode?.code == null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "instructor__button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.generateInviteCode && _ctx.generateInviteCode(...args)))
              }, [
                _cache[5] || (_cache[5] = _createTextVNode(" Generate Code ")),
                (this.errorInviteCode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(this.errorInviteCode), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (this.generateCode?.code != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Generate school code "))
            : _createCommentVNode("", true),
          (this.generateCode?.code != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(this.generateCode?.code), 1),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_CopyInstructor, { onClick: _ctx.copyText }, null, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_Edit, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editInstructor(this.instructorData.id)))
            }),
            _createVNode(_component_Delete, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDeletePoup(this.instructorData.id)))
            })
          ])
        ]),
        (this.instructorData?.students?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"table__head\" data-v-54a60955><div class=\"table__head-id\" data-v-54a60955> ID <div class=\"table__sort-block\" data-v-54a60955></div></div><div class=\"table__head-little-tr\" data-v-54a60955> Picture </div><div class=\"table__head-big-tr\" data-v-54a60955> Full Name <div class=\"table__sort-block\" data-v-54a60955></div></div><div class=\"table__head-big-tr\" data-v-54a60955> Email </div><div class=\"table__head-big-tr\" data-v-54a60955> Phone Number <div class=\"table__sort-block\" data-v-54a60955></div></div></div>", 1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.instructorData?.students, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "table__body",
                  key: item,
                  onClick: ($event: any) => (_ctx.goToStydent(item.id))
                }, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(item.id), 1),
                  _createElementVNode("div", _hoisted_19, [
                    (item?.image === null)
                      ? (_openBlock(), _createBlock(_component_Picture, { key: 0 }))
                      : _createCommentVNode("", true),
                    (item.image !== null)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: item.image,
                          alt: "",
                          class: "table__image"
                        }, null, 8, _hoisted_20))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_21, _toDisplayString(item?.full_name), 1),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "table__body-big-tr" }, " mirabellavi@gmail.com ", -1)),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(item?.phone?.value), 1)
                ], 8, _hoisted_17))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "instructor__popup-title" }, " Are you sure? ", -1)),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", {
                class: "instructor__popup-button-yes",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.deleteInstructor && _ctx.deleteInstructor(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "instructor__popup-button-no",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No "),
              (this.errorInstructors)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createVNode(_component_AlertBlock, {
                      message: this.errorInstructors
                    }, null, 8, ["message"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}